import { BrowserRouter, Routes, Route } from "react-router-dom";
import SideBar from "../components/SideBar";
import "../css/pages.css";
import routes from "../utils/routes";
import Dashboard from "../pages/index/Dashboard";
import Header from "../components/Header";
import Submissions from "./app/Submissions";
import Wallet from "./app/Wallet";
import Sales from "./app/Sales";
import ConfigureCart from "../pages/index/ConfigureCart";
import OnlineOrders from "./app/OnlineOrders";
import orderAlarm from "../assets/audio/alarm.mp3";
import AudioPlayer from "../components/Audio";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import client from "../api";
import DataContext from "../contexts/DataContext";
import useNetworkStatus from "../hooks/useNetworkStatus";
import ChangePicture from "../pages/index/ChangePicture";
import Debug from "../pages/index/Debug";

function Base(props) {
  const [playOrderAlarm, setPlayOrderAlarm] = useState(false);
  const { isOnline } = useNetworkStatus();

  useEffect(() => {
    // if (isOnline) {
    const socket = io(client.getUri().replace("/api", ""), {
      jsonp: false,
    });
    socket.on("new-order", (data) => {
      setPlayOrderAlarm(true);
    });
    // } else {
    //   setPlayOrderAlarm(false);
    // }
  }, [isOnline]);

  return (
    <DataContext.Provider value={{ playOrderAlarm, setPlayOrderAlarm }}>
      <BrowserRouter>
        <div className="base_container raleway">
          <SideBar />
          <div className="content flex flex-column">
            <Header />
            <div className="routes container">
              <Routes>
                <Route path={routes.baseUrl} element={<Dashboard />} />
                <Route path={routes.configure} element={<ConfigureCart />} />
                <Route path={"/support"} element={<ChangePicture />} />
                <Route path={"/debug"} element={<Debug />} />
                <Route
                  path={routes.app.submissions.baseUrl}
                  element={<Submissions />}
                />
                <Route path={routes.app.wallet.baseUrl} element={<Wallet />} />
                <Route path={routes.app.sales.baseUrl} element={<Sales />} />
                <Route
                  path={routes.app.orders.baseUrl}
                  element={<OnlineOrders />}
                />
              </Routes>
            </div>
          </div>
          <AudioPlayer
            setIsPlaying={setPlayOrderAlarm}
            isPlaying={playOrderAlarm}
            audioSrc={orderAlarm}
          />
        </div>
      </BrowserRouter>
    </DataContext.Provider>
  );
}

export default Base;
