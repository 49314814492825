import { useEffect } from "react";
import Button from "../Button";
import { useFormikContext } from "formik";

export default function Submit({
  Wrapper = Button,
  disabled,
  onChange,
  ...others
}) {
  const { handleSubmit, isValid, values } = useFormikContext();
  useEffect(() => {
    if (typeof onChange === "function") onChange(values);
  }, [values]);
  return (
    <Wrapper
      {...others}
      type="submit"
      disabled={!isValid || disabled}
      onClick={handleSubmit}
    />
  );
}
