import Submissions from "../../components/table/active/Submissions";

function Dashboard(props) {
  return (
    <div>
      <Submissions />
    </div>
  );
}

export default Dashboard;
