import { Routes, Route } from "react-router-dom";
import routes from "../../utils/routes";
import SubmitConcept from "../../pages/submissions/SubmitConcept";
import Dashboard from "../../pages/submissions/Dashboard";
import ViewSubmissions from "../../pages/submissions/ViewSubmissions";

function Submissions(props) {
  return (
    <Routes>
      <Route path={routes.baseUrl} element={<Dashboard />} />
      <Route path={routes.new} element={<SubmitConcept />} />
      <Route path={routes.details} element={<ViewSubmissions />} />
    </Routes>
  );
}

export default Submissions;
