import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import routes from "../utils/routes";

export const Auth = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.baseUrl} element={<Login />} />
        <Route path={routes.auth.login} element={<Login />} />
        <Route path={routes.auth.register} element={<Register />} />
      </Routes>
    </BrowserRouter>
  );
};
