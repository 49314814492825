import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useAPI from "./useAPI";
import { objectToFormData } from "../../utils";
import urls from "../../api/urls";
import { toast } from "react-toastify";

function useConcepts(props) {
  const [submissions, setSubmissions] = useState([]);
  const [requests, setRequests] = useState([]);
  const { get, post, isLoading } = useAPI();

  const navigate = useNavigate();

  const addRequest = async (fdata, images, packagingImages) => {
    if (images.length !== 4) return toast.warn("Concept images not selected");
    if (packagingImages.length !== 4)
      return toast.warn("Packaging images not selected");
    fdata = objectToFormData(fdata);
    images.forEach((file) => {
      fdata.append("images", file);
    });
    packagingImages.forEach((file) => {
      fdata.append("packagingImages", file);
    });

    const { error, data } = await post(urls.businesses.submissions, fdata);
    if (error) return;
    toast.success(data.message);
    navigate(-1);
  };

  const getSubmissions = async (role) => {
    const { data, error } = await get(
      role === "school"
        ? urls.businesses.submissions
        : urls.businesses.submissionsMe
    );
    if (error) return;
    setSubmissions(data.data.submissions);
  };

  return {
    requests,
    isLoading,
    submissions,
    setRequests,
    addRequest,
    getSubmissions,
  };
}

export default useConcepts;
