import client from "../api";
import urls from "../api/urls";

export const getNumber = (n) => (n < 10 ? `0${n}` : n);
export const getImageUrl = (url) =>
  url?.includes("http") || url?.includes("file://")
    ? url
    : client.getUri() + urls.files.baseUrl + url;

export const currencyFormatter = (n, pre = "₦") =>
  (pre || "") + n?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export function objectToFormData(
  obj,
  formData = new FormData(),
  parentKey = null
) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const fullKey = parentKey ? `${parentKey}[${key}]` : key;

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        objectToFormData(value, formData, fullKey);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          objectToFormData(item, formData, `${fullKey}[${index}]`);
        });
      } else {
        formData.append(fullKey, value);
      }
    }
  }

  return formData;
}

export const selectFromObject = (fields = [], obj) => {
  const output = {};
  fields.forEach((f) => {
    output[f] = obj[f];
  });
  return output;
};

export const excludeFromObject = (fields = [], obj) => {
  const output = {};
  Object.keys(obj).forEach((f) => {
    if (!fields.includes(f)) {
      output[f] = obj[f];
    }
  });
  return output;
};

export function capitalizeFirstLetter(string) {
  if (string.length === 0) return string; // handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDateTime(date) {
  // Days of the week and months arrays
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get time in HH:MM format
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Add leading zero to minutes if needed
  if (minutes < 10) minutes = "0" + minutes;
  if (hours < 10) hours = "0" + hours;

  const time = `${hours}:${minutes}`;

  // Get date in "Day d M yyyy" format
  const day = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const formattedDate = `${day} ${dayOfMonth} ${month} ${year}`;

  return { time, date: formattedDate };
}
