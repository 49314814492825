const routes = {
  baseUrl: "/",
  home: "/home",
  configure: "/configure",
  new: "/new",
  details: "/details",
  success: "/success",
  app: {
    dashboard: "/dashboard",
    form: "/form",
    home: {
      baseUrl: "/home/*",
      subs: "/subscriptions",
      icarts: "/icarts",
    },
    settings: {
      profile: "/profile",
      banking: "/banking",
      contact: "/contact",
      password: "/password",
      baseUrl: "/settings/*",
    },
    submissions: {
      baseUrl: "/submissions/*",
    },
    sales: {
      baseUrl: "/sales/*",
    },
    orders: {
      baseUrl: "/online-orders/*",
    },
    wallet: {
      topup: "/topup",
      baseUrl: "/wallet/*",
      accounts: "/accounts",
      pin: "/pin",
      withdraw: "/withdraw",
      transaction: "/transaction",
      transactions: "/transactions",
    },
    apls: {
      baseUrl: "/apls/*",
      result: "/results",
    },
    management: {
      baseUrl: "/management/*",
    },
  },
  auth: {
    register: "/register",
    baseUrl: "/auth/*",
    login: "/login",
  },
};

export const getUrl = (url) => url.replace("/*", "");

export default routes;
