import Dashboard from "../../pages/orders/Dashboard";
import { Routes, Route } from "react-router-dom";
import routes from "../../utils/routes";
import ViewOrder from "../../pages/orders/ViewOrder";

function OnlineOrders(props) {
  return (
    <Routes>
      <Route path={routes.baseUrl} element={<Dashboard />} />
      <Route path={routes.details} element={<ViewOrder />} />
    </Routes>
  );
}

export default OnlineOrders;
