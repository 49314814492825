import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string().required().label("Email").email(),
  password: Yup.string().required().label("Password"),
});

export const registerSchema = Yup.object().shape({
  email: Yup.string().required().label("Email").email(),
  password: Yup.string().required().label("Password"),
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  phoneNumber: Yup.number().required().label("Phone Number"),
  role: Yup.string().required().label("Role"),
});
