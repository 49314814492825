import { useEffect } from "react";
import Table from "../Table";
import { Link, useNavigate } from "react-router-dom";
import routes, { getUrl } from "../../../utils/routes";
import { currencyFormatter } from "../../../utils/func";
import useBusiness from "../../../hooks/api/useBusiness";
import { formatDate } from "../../../utils";

function Sales(props) {
  const navigate = useNavigate();
  const route = () => navigate(getUrl(routes.app.sales.baseUrl) + routes.new);
  const { getSales, sales, isLoading } = useBusiness();

  useEffect(() => {
    getSales();
  }, []);

  return (
    <div>
      <Table
        showSearch={false}
        loading={isLoading}
        btn={{ title: "ADD", onClick: route }}
        title="Sales"
        head={head}
        data={sales}
      />
    </div>
  );
}

export default Sales;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Item",
    target: "menu.name",
  },
  {
    title: "Quantity",
    target: "quantity",
  },
  {
    title: "iCart",
    target: "kiosk.id",
  },
  {
    title: "Price",
    target: "priceCapture",
    render: currencyFormatter,
  },
  {
    title: "Total",
    target: ["priceCapture", "quantity"],
    render: (v) => currencyFormatter(v[0] * v[1]),
  },
  {
    title: "Date",
    target: "createdAt",
    render: formatDate,
  },
];
