import Dashboard from "../../pages/wallet/Dashboard";
import Withdraw from "../../pages/wallet/Withdraw";
import { Routes, Route } from "react-router-dom";
import TopUp from "../../pages/wallet/TopUp";
import routes from "../../utils/routes";
import ChangePin from "../../pages/wallet/ChangePin";
import BankAccounts from "../../pages/wallet/BankAccounts";

function Wallet(props) {
  return (
    <Routes>
      <Route path={routes.baseUrl} element={<Dashboard />} />
      <Route path={routes.app.wallet.topup} element={<TopUp />} />
      <Route path={routes.app.wallet.withdraw} element={<Withdraw />} />
      <Route path={routes.app.wallet.pin} element={<ChangePin />} />
      <Route path={routes.app.wallet.accounts} element={<BankAccounts />} />
    </Routes>
  );
}

export default Wallet;
