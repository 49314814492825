import { useEffect, useState } from "react";
import Select from "../../components/Select";
import useBanking from "../../hooks/api/useBanking";
import Input from "../../components/Input";
import Button from "../../components/Button";

function BankAccounts(props) {
  const [selectedBank, setSelectedBank] = useState(null);
  const [accountNumber, setAccountNumber] = useState("");
  const { isLoading, banks, getBanks, verifiedData, verifyAccount, addBank } =
    useBanking();

  const handleAddBank = () =>
    addBank({
      accountNumber,
      bankCode: selectedBank.value,
      accountName: verifiedData.accountName,
      bankName: selectedBank.label,
    });
  useEffect(() => {
    if (accountNumber.length === 10 && selectedBank?.value) {
      verifyAccount(selectedBank.value, accountNumber);
    }
  }, [accountNumber, selectedBank]);

  useEffect(() => {
    getBanks();
  }, []);
  return (
    <div>
      <h2 className="t-blue">Add Recieving Account</h2>
      <br />
      <br />
      <Select
        value={selectedBank?.label}
        onSelect={setSelectedBank}
        placeholder="Bank Name"
        options={banks.map((a) => ({
          label: a.name,
          value: {
            value: a.code,
            label: a.name,
          },
          disabled: !a.active,
        }))}
      />
      <br />
      <Input
        value={accountNumber}
        onChange={setAccountNumber}
        placeholder="Account Number"
      />
      {verifiedData && (
        <h2 className="card t-blue verified account">
          {verifiedData.accountName}
        </h2>
      )}
      <Button
        onClick={handleAddBank}
        loading={isLoading}
        disabled={!accountNumber || !selectedBank || !verifiedData?.accountName}
        title="Save"
        className="btn-submit"
      />
    </div>
  );
}

export default BankAccounts;
