import { useContext, useRef } from "react";
import AppContext from "../contexts/AppContext";
import { currencyFormatter, formatDateTime } from "../utils/func";
import Button from "./Button";
import { useReactToPrint } from "react-to-print";

function Reciept({ order, handleClose }) {
  const { user } = useContext(AppContext);
  const ref = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  return (
    <div className="reciept-cnt">
      <Button
        style={{ marginLeft: "auto" }}
        className="btn-submit"
        onClick={handlePrint}
        title="Print"
      />
      <br />
      <div ref={ref} class="reciept-body">
        <h2 class="center">iCart Africa</h2>
        <p class="center">Plot 11 Inna Plaza</p>
        <p class="center">Gaduwa Abuja</p>
        <p class="center">Tel: +234 903 792 0018</p>
        <br />
        <hr />
        <div class="r-info">
          <p>
            <strong>Date:</strong> {formatDateTime(new Date()).date}
          </p>
          <p>
            <strong>Time:</strong> {formatDateTime(new Date()).time}
          </p>
          <p>
            <strong>Operator:</strong> {user.firstName} {user.lastName}
          </p>
          <p>
            <strong>Order ID: {order.id}y</strong>
          </p>
          <p>
            <strong>iCart ID:</strong>{" "}
            {JSON.parse(localStorage.getItem("kiosk"))?.value}
          </p>
        </div>
        <hr />
        <table>
          {order.items.map((i, idx) => (
            <tr class="sp-tr">
              <td>
                {i.quantity} {i.item.name}
              </td>
              <td>
                {currencyFormatter((i.item.price * i.quantity).toFixed(2), "N")}
              </td>
            </tr>
          ))}
        </table>
        <hr />
        <hr />
        <table>
          <tr>
            <td>VAT: 0% VAT</td>
          </tr>
          <tr>
            <td>
              VAT Amount:{" "}
              <strong>{currencyFormatter((0.0).toFixed(2), "N")}</strong>
            </td>
          </tr>
          <tr>
            <td>
              Delivery Fee:{" "}
              <strong>{currencyFormatter(order.deliveryFee, "N")}</strong>
            </td>
          </tr>
        </table>
        <br />
        <hr />
        <table>
          <tr class="sp-tr">
            <td>VAT</td>
            <td>{currencyFormatter((0.0).toFixed(2), "N")}</td>
          </tr>
          <tr class="sp-tr">
            <td>Total</td>
            <td>{currencyFormatter(order.costTotal, "N")}</td>
          </tr>
        </table>
        <hr />
        <hr />
        <br />
        <h3 class="center">
          THANK YOU ({order.customerInformation.phoneNumber})
        </h3>
        <span class="center"> DELIVER TO </span>
        <p class="center">
          <strong>{order.deliveryTo.address}</strong>
        </p>
      </div>
      <br />
      <Button
        style={{ marginLeft: "auto" }}
        className="btn-submit"
        onClick={handleClose}
        title="Close"
      />
    </div>
  );
}

export default Reciept;
