import { useState } from "react";
import urls from "../../api/urls";
import useAPI from "./useAPI";

const useBanking = () => {
  const [banks, setBanks] = useState([]);
  const [savedAccounts, setSavedAccounts] = useState([]);
  const [verifiedData, setVerifiedData] = useState(null);
  const { get, isLoading, post, patch } = useAPI();

  const getBanks = async () => {
    const { data, error } = await get(urls.banking.banks);
    if (error) return;
    setBanks(data.data.banks);
  };

  const verifyAccount = async (bankCode, accountNumber) => {
    setVerifiedData(null);
    const { data, error } = await post(urls.banking.verify, {
      bankCode,
      accountNumber,
    });
    if (error) return;
    setVerifiedData(data.data.response);
  };

  const addBank = async (details, cb) => {
    const { error } = await post(urls.banking.add, details);
    if (error) return;
    await getSavedAccounts();
    if (typeof cb === "function") cb();
  };

  const getSavedAccounts = async () => {
    const { data, error } = await get(urls.banking.accounts);
    if (error) return;
    setSavedAccounts(data.data.accounts);
  };
  const updateAccountStatus = async (id, active) => {
    await patch(urls.banking.accounts + "/" + id, { active });
    getSavedAccounts();
  };

  return {
    isLoading,
    getBanks,
    banks,
    verifiedData,
    verifyAccount,
    addBank,
    savedAccounts,
    getSavedAccounts,
    updateAccountStatus,
  };
};

export default useBanking;
