import { useState, useEffect } from "react";
import { toast } from "react-toastify";

function useLocation() {
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [locationData, setLocationData] = useState(null);

  const getLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocationData(position.coords);
          setPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (err) => {
          toast.error("Unable to get your location");
        }
      );
    } else {
      toast.error("Geolocation is not available in your browser.");
    }
  };

  const resetLocation = () => setPosition({ latitude: null, longitude: null });

  useEffect(() => {
    getLocation();
  }, []);

  return { position, getLocation, resetLocation, locationData };
}

export default useLocation;
