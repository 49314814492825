import { useEffect } from "react";
import "../../css/wallet.css";
import useWallet from "../../hooks/api/useWallet";
import { formatDate, numberFormatter } from "../../utils";
import Revenue from "../../components/charts/Revenue";
import Table from "../../components/table/Table";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import routes, { getUrl } from "../../utils/routes";

const Card = ({ title }) => (
  <div className="card hover c-card align-center flex">
    <div className="ico"></div>
    <div>
      <span>{title}</span>
      <h3 className="t-blue inter">{numberFormatter(0)}</h3>
    </div>
  </div>
);

function Dashboard(props) {
  const { wallet, getWallet, getTransactions, transactions } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    getWallet();
    getTransactions();
  }, []);

  return (
    <div>
      <div className="flex cards">
        <div className="balance hover">
          <span>Current Balance</span>
          <h2 className="inter">{numberFormatter(wallet.balance)}</h2>
          <br />
          <div className="flex card-btns">
            <Button
              onClick={() =>
                navigate(
                  getUrl(routes.app.wallet.baseUrl + routes.app.wallet.topup)
                )
              }
              outline
              title="Top Up"
            />
            <Button
              onClick={() =>
                navigate(
                  getUrl(routes.app.wallet.baseUrl + routes.app.wallet.withdraw)
                )
              }
              title="Withdraw"
            />
          </div>
        </div>
        <div className="cards show-cards">
          <Card title="Income" />
          <Card title="Spending" />
        </div>
        <Revenue className="w-gr" />
      </div>
      <Table title="Transactions" head={head} data={transactions} />
    </div>
  );
}

export default Dashboard;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Title",
    target: "title",
  },
  {
    title: "Amount",
    render: (v) => numberFormatter(v),
    target: "amount",
  },
  {
    title: "Amount Paid",
    render: (v) => numberFormatter(v),
    target: "amountPaid",
  },
  {
    title: "Type",
    target: "type",
    render: (v) => <span className={`status ${v}`}>{v}</span>,
  },
  {
    title: "Status",
    target: "status",
    render: (v) => <span className={`status ${v}`}>{v}</span>,
  },
  {
    title: "Date",
    target: "createdAt",
    render: formatDate,
  },
];
