const urls = Object.freeze({
  auth: {
    login: "/v1/auth/login",
    register: "/v1/auth/signup",
    access: "/v1/auth/refresh-access",
    resetPassword: "/v1/auth/reset-password",
  },
  profile: {
    baseUrl: "/v1/profile",
    accounts: "/v1/admin/players",
    me: "/v1/profile/me",
    password: "/v1/profile/password",
    image: "/v1/profile/image",
    info: "/v1/profile/info",
  },
  files: {
    baseUrl: "/v1/files/",
  },
  wallet: {
    baseUrl: "/v1/wallet",
    me: "/v1/wallet",
    transactions:
      "/v1/wallet/transactions?pageNumber={{pageNumber}}&limit={{limit}}",
    fund: "/v1/wallet/fund",
    link: "/v1/wallet/id/link",
    withdraw: "/v1/wallet/withdraw",
    changePin: "/v1/wallet/change-pin",
  },
  monnify: {
    baseUrl: "https://sandbox.sdk.monnify.com",
    checkout: "https://sandbox.sdk.monnify.com/checkout/",
  },
  paystack: {
    checkout: "https://checkout.paystack.com/",
  },
  notifications: {
    baseUrl: "/v1/notifications",
    me: "/v1/notifications",
    unread: "/v1/notifications/unread",
    read: "/v1/notifications/read",
    pushToken: "/v1/notifications/device-push-token",
  },
  onlineOrders: {
    baseUrl: "/v1/online-orders",
    orders: "/v1/online-orders/protected/orders?icart=",
    kiosks: "/v1/online-orders/protected/kiosks",
    details: "/v1/online-orders/:id/details",
    completeOrder: "/v1/online-orders/protected/orders/complete?id=",
    confirmPayment: "/v1/online-orders/protected/orders/confirm-payment?id=",
    acceptOrder: "/v1/online-orders/protected/orders/accept-order?id=",
  },
  businesses: {
    baseUrl: "/v1/admin/business",
    concepts: "/v1/business/concepts",
    submissions: "/v1/business/concepts/submissions",
    submissionsMe: "/v1/business/concepts/submissions/me",
    machines: {
      conceptsMachines: "/v1/business/machines?concept=",
    },
    subscriptions: {
      me: "/v1/business/subscriptions/me",
      plans: "/v1/business/subscriptions/plans",
      subscribe: "/v1/business/subscriptions/subscribe",
    },
    inventory: {
      get: "/v1/business/inventory?subscription=",
    },
    sales: {
      baseUrl: "/v1/business/sales",
    },
    locations: {
      baseUrl: "/v1/business/locations",
      apls: "/v1/business/locations/apls?city=",
    },
    menus: {
      baseUrl: "/v1/business/menus",
    },
  },
  banking: {
    baseUrl: "/v1/banking",
    banks: "/v1/banking/banks",
    accounts: "/v1/banking/accounts",
    add: "/v1/banking/accounts/new",
    verify: "/v1/banking/verify",
  },
  utils: {
    countries: "https://countries-pudh.onrender.com",
  },
  menu: {
    getConceptMenus: "/v1/business/menus/concepts?id=",
    getMenuIngredients: "/v1/business/menus/ingredients?id=",
  },
  operations: {
    baseUrl: "/v1/operations",
    cities: {
      baseUrl: "/v1/operations/cities",
      new: "/v1/operations/cities/new",
      update: "/v1/operations/cities/",
    },
  },
  orders: {
    baseUrl: "/v1/orders",
    new: "/v1/orders/new",
    me: "/v1/orders/me?type=",
    count: "/v1/orders/count?subscription=",
    items: "v1/orders/:id/items",
    cancel: "v1/orders/:id/cancel",
  },
});
export default urls;
