import { useEffect, useRef, useState } from "react";
import useMenus from "../../hooks/api/useMenus";
import Table from "../../components/table/Table";
import { currencyFormatter } from "../../utils/func";
import Button from "../../components/Button";
import useBusiness from "../../hooks/api/useBusiness";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import useLocation from "../../hooks/useLcoation";

const Counter = ({ setItems, items, id, name, price }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      setItems([
        { id, name, price, count },
        ...items.filter((i) => i.id !== id),
      ]);
    } else {
      setItems([...items.filter((i) => i.id !== id)]);
    }
  }, [count]);

  return (
    <div className="flex counter align-center justify-between">
      <button onClick={() => setCount(count + 1)}>+</button>
      <span>{count}</span>
      <button disabled={count <= 0} onClick={() => setCount(count - 1)}>
        -
      </button>
    </div>
  );
};

function New(props) {
  const { position } = useLocation();
  const [isPrinting, setIsPrinting] = useState(false);
  const [items, setItems] = useState([]);
  const { getMenus, render, search } = useMenus();
  const { isLoading, addSale } = useBusiness();
  const navigate = useNavigate();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => navigate(-1),
  });

  const print = () => {
    setIsPrinting(true);
    setTimeout(() => {
      handlePrint();
    }, 500);
  };

  const saveSales = () => {
    addSale(
      { items: items.map((i) => ({ id: i.id, quantity: i.count })), position },
      print
    );
  };

  const total = items.reduce((p, c) => p + c.price * c.count, 0);

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <div>
      <div className="flex sales-cnt justify-between">
        <div className="table-cnt">
          <Table
            onSearch={search}
            title="Menus"
            data={render}
            head={[
              ...head,
              {
                title: "",
                target: ["_id", "name", "price"],
                render: (v) => (
                  <Counter
                    name={v[1]}
                    id={v[0]}
                    price={v[2]}
                    setItems={setItems}
                    items={items}
                  />
                ),
              },
            ]}
          />
        </div>
        <div ref={componentRef} className="sales-data card">
          <h2 className="t-blue">Purcase at iCart</h2>
          <br />
          {items.map((i, idx) => (
            <div className="hover item-sales" key={idx}>
              <span>{i.name}</span>
              <span>x{i.count}</span>
              <span>{currencyFormatter(i.price * i.count)}</span>
            </div>
          ))}
          <div
            style={{ marginTop: "auto" }}
            className="hover item-sales justify-between"
          >
            <span className="f800 t-blue">VAT</span>
            <span>{currencyFormatter((0.075 * total).toFixed(2))}</span>
          </div>
          <div className="hover item-sales justify-between">
            <span className="f800 t-blue">Total</span>
            <span>{currencyFormatter((total + 0.075 * total).toFixed(2))}</span>
          </div>
          <br />
          {isPrinting ? null : (
            <Button
              onClick={saveSales}
              loading={isLoading}
              title="Confirm"
              className="btn-submit"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default New;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Name",
    target: "name",
  },
  {
    title: "Price",
    target: "price",
    render: currencyFormatter,
  },
];
