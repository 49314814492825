import "../../css/table.css";
import Button from "../Button";
import Input from "../Input";
import Loader from "../Loader";
import Row from "./Row";

function Table({
  title,
  head,
  data = [],
  btn,
  showSearch = true,
  onSearch,
  style,
  loading,
}) {
  return (
    <div className="table card" style={style}>
      <div className="flex justify-between">
        <h2 className="t-blue f600 ttitle">{title}</h2>
        {showSearch && <Input placeholder="Search" onChange={onSearch} />}
        {btn && (
          <div className="table-footer flex justify-end">
            <Button {...btn} className="btn-submit" />
          </div>
        )}
      </div>
      <Row isHead cells={head} data />
      {loading && <Loader loading />}
      {!loading &&
        data.map((d, idx) => (
          <Row cells={head} data={d} key={idx} didx={idx} />
        ))}
    </div>
  );
}

export default Table;
