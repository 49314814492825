import * as Yup from "yup";

export const kioskSchema = Yup.object().shape({
  id: Yup.string().required(),
});

export const conceptSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().optional(),
  rio: Yup.number().required(),
  rioDuration: Yup.string().required(),
});

export const MenuSchema = Yup.object().shape({
  name: Yup.string().required(),
  price: Yup.number().required(),
});

export const ingredientsSchema = Yup.object().shape({
  name: Yup.string().required(),
  unit: Yup.string().required(),
  price: Yup.number().required(),
});

export const machineSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
  price: Yup.number().required(),
});

export const conceptSubmission = Yup.object().shape({
  description: Yup.string().required(),
  packaging: Yup.string().required(),
  compensation: Yup.string().required(),
  name: Yup.string().required(),
  origin: Yup.string().required(),
  bestEatingTime: Yup.string().required(),
  bestServeTo: Yup.string().required(),
  expiring: Yup.number().required(),
});
