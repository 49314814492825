import { useContext } from "react";
import urls from "../../api/urls";
import AppContext from "../../contexts/AppContext";
import { toast } from "react-toastify";
import useAPI from "./useAPI";

const useProfile = () => {
  const { setUser } = useContext(AppContext);
  const { get, isLoading, patch } = useAPI();

  const sycnProfile = async (profile) => {
    if (!profile) {
      const { data, error } = await get(urls.profile.me);
      if (error) return;
      profile = data.data.profile;
    }
    setUser(profile);
  };

  const changePassword = async (details, cb) => {
    const { error, data } = await patch(urls.profile.password, details);
    if (error) return;
    toast.success(data.message);
    if (typeof cb === "function") cb();
  };

  const updateInfo = async (details, cb) => {
    const { error, data } = await patch(urls.profile.info, details);
    if (error) return;
    toast.success(data.message);
    sycnProfile();
    if (typeof cb === "function") cb();
  };

  const changeImage = async (image) => {
    const formData = new FormData();
    formData.append("profileImage", image);
    const { error, data } = await patch(urls.profile.image, formData);
    if (error || typeof data === "string") return;
    toast.success(data.message);
    sycnProfile(data.data.profile);
  };

  return {
    sycnProfile,
    isLoading,
    changePassword,
    changeImage,
    updateInfo,
  };
};

export default useProfile;
