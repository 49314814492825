import AppContext from "../contexts/AppContext";
import { useLocation } from "react-router-dom";
import { getImageUrl } from "../utils/func";
import { useContext, useEffect, useState } from "react";
import svg from "../utils/svg";
import "../css/header.css";
import Back from "./Back";
import store from "../utils/store";
import Button from "./Button";

const switchUser = (user) => {
  store.setTokens(user.tokens);
  window.location = "/";
};

const Image = ({ alt, src }) => (
  <img alt={alt} crossOrigin="anonymous" src={getImageUrl(src)} />
);

export const RenderUser = ({ img, name, email, user }) => {
  return (
    <div
      onClick={() => switchUser(user)}
      className="user-switch flex align-center"
    >
      <div className="img">{img ? <Image src={img} /> : svg.profileSm()}</div>
      <div>
        <h3>{name}</h3>
        <span>{email}</span>
      </div>
    </div>
  );
};

function Header(props) {
  const [showAccounts, setShowAccounts] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const { user } = useContext(AppContext);
  const { pathname } = useLocation();

  useEffect(() => {
    const users = store.getItem("accounts") || [];
    setAccounts(users);
  }, []);

  const newLogin = () => {
    store.removeTokens();
    window.location = "/";
  };

  return (
    <header className="container flex align-center">
      {showAccounts && (
        <div className="profiles shadow card">
          {accounts.map((a, idx) => (
            <RenderUser
              key={idx}
              user={a}
              name={`${a.firstName} ${a.lastName}`}
              email={a.email}
              img={a.profileImage}
            />
          ))}
          <Button className="new-login" title="New Login" onClick={newLogin} />
        </div>
      )}
      {pathname !== "/" && <Back />}
      <h2 className="f700 t-blue">Dashboard</h2>
      <div className="user-info align-center card flex justify-between">
        <div className="bell flex justify-center align-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 18.2071V18.5H4.5V18.2071L6.35355 16.3536L6.5 16.2071V16V11C6.5 8.09437 8.02219 5.78092 10.6153 5.16653L11 5.07538V4.68V4C11 3.44614 11.4461 3 12 3C12.5539 3 13 3.44614 13 4V4.68V5.07506L13.3843 5.16644C15.9681 5.78076 17.5 8.10482 17.5 11V16V16.2071L17.6464 16.3536L19.5 18.2071ZM13.4135 20.5C13.2061 21.0806 12.6488 21.5 12 21.5C11.3443 21.5 10.7907 21.0813 10.5854 20.5H13.4135Z"
              fill="white"
              stroke="#BCBCC4"
            />
            <circle cx="17" cy="6" r="4.5" fill="#FF0202" stroke="white" />
          </svg>
        </div>
        <div onClick={() => setShowAccounts(!showAccounts)} className="info">
          <h2 className="f600 t-default">
            {user.firstName} {user.lastName}
          </h2>
          <span className="f500 t-blue">{user.role}</span>
        </div>
        {user.profileImage ? (
          <Image
            alt={`${user.firstName} ${user.lastName}`}
            src={user.profileImage}
          />
        ) : (
          svg.profileSm()
        )}
      </div>
    </header>
  );
}

export default Header;
