export const navLinks = Object.freeze([
  {
    title: "Dashboard",
    to: "/",
    renderSvg: () => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 2.16667V3.83333H10.5V2.16667H13.8333ZM5.5 2.16667V7.16667H2.16667V2.16667H5.5ZM13.8333 8.83333V13.8333H10.5V8.83333H13.8333ZM5.5 12.1667V13.8333H2.16667V12.1667H5.5ZM15.5 0.5H8.83333V5.5H15.5V0.5ZM7.16667 0.5H0.5V8.83333H7.16667V0.5ZM15.5 7.16667H8.83333V15.5H15.5V7.16667ZM7.16667 10.5H0.5V15.5H7.16667V10.5Z"
          fill="#0E79B7"
        />
      </svg>
    ),
  },

  {
    title: "Wallet",
    to: "/wallet",
    renderSvg: () => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333Z"
          fill="#0E79B7"
        />
        <path d="M5.5 8H3.83334V12.1667H5.5V8Z" fill="#0E79B7" />
        <path
          d="M12.1667 3.83333H10.5V12.1667H12.1667V3.83333Z"
          fill="#0E79B7"
        />
        <path
          d="M8.83333 9.66667H7.16666V12.1667H8.83333V9.66667Z"
          fill="#0E79B7"
        />
        <path d="M8.83333 6.33333H7.16666V8H8.83333V6.33333Z" fill="#0E79B7" />
      </svg>
    ),
  },
  {
    title: "Support",
    to: "/support",
    renderSvg: () => (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 8.18333C16.5 3.60833 12.95 0.5 9 0.5C5.09166 0.5 1.5 3.54167 1.5 8.23333C0.999997 8.51667 0.666664 9.05 0.666664 9.66667V11.3333C0.666664 12.25 1.41666 13 2.33333 13H3.16666V7.91667C3.16666 4.69167 5.775 2.08333 9 2.08333C12.225 2.08333 14.8333 4.69167 14.8333 7.91667V13.8333H8.16666V15.5H14.8333C15.75 15.5 16.5 14.75 16.5 13.8333V12.8167C16.9917 12.5583 17.3333 12.05 17.3333 11.45V9.53333C17.3333 8.95 16.9917 8.44167 16.5 8.18333Z"
          fill="#0E79B7"
        />
        <path
          d="M6.5 9.66667C6.96023 9.66667 7.33333 9.29357 7.33333 8.83333C7.33333 8.3731 6.96023 8 6.5 8C6.03976 8 5.66666 8.3731 5.66666 8.83333C5.66666 9.29357 6.03976 9.66667 6.5 9.66667Z"
          fill="#0E79B7"
        />
        <path
          d="M11.5 9.66667C11.9602 9.66667 12.3333 9.29357 12.3333 8.83333C12.3333 8.3731 11.9602 8 11.5 8C11.0398 8 10.6667 8.3731 10.6667 8.83333C10.6667 9.29357 11.0398 9.66667 11.5 9.66667Z"
          fill="#0E79B7"
        />
        <path
          d="M14 7.19167C13.6 4.81667 11.5333 3 9.04167 3C6.51667 3 3.8 5.09167 4.01667 8.375C6.075 7.53333 7.625 5.7 8.06667 3.46667C9.15833 5.65833 11.4 7.16667 14 7.19167Z"
          fill="#0E79B7"
        />
      </svg>
    ),
  },
]);

export const navLinksPrep = Object.freeze([
  ...navLinks,
  {
    title: "Submissions",
    to: "/submissions",
    renderSvg: () => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333Z"
          fill="#0E79B7"
        />
        <path d="M5.5 8H3.83334V12.1667H5.5V8Z" fill="#0E79B7" />
        <path
          d="M12.1667 3.83333H10.5V12.1667H12.1667V3.83333Z"
          fill="#0E79B7"
        />
        <path
          d="M8.83333 9.66667H7.16666V12.1667H8.83333V9.66667Z"
          fill="#0E79B7"
        />
        <path d="M8.83333 6.33333H7.16666V8H8.83333V6.33333Z" fill="#0E79B7" />
      </svg>
    ),
  },
]);

export const navLinksOpr = Object.freeze([
  ...navLinks,
  {
    title: "Sales",
    to: "/sales",
    renderSvg: () => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333Z"
          fill="#0E79B7"
        />
        <path d="M5.5 8H3.83334V12.1667H5.5V8Z" fill="#0E79B7" />
        <path
          d="M12.1667 3.83333H10.5V12.1667H12.1667V3.83333Z"
          fill="#0E79B7"
        />
        <path
          d="M8.83333 9.66667H7.16666V12.1667H8.83333V9.66667Z"
          fill="#0E79B7"
        />
        <path d="M8.83333 6.33333H7.16666V8H8.83333V6.33333Z" fill="#0E79B7" />
      </svg>
    ),
  },
  {
    title: "Online Orders",
    to: "/online-orders",
    renderSvg: () => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333Z"
          fill="#0E79B7"
        />
        <path d="M5.5 8H3.83334V12.1667H5.5V8Z" fill="#0E79B7" />
        <path
          d="M12.1667 3.83333H10.5V12.1667H12.1667V3.83333Z"
          fill="#0E79B7"
        />
        <path
          d="M8.83333 9.66667H7.16666V12.1667H8.83333V9.66667Z"
          fill="#0E79B7"
        />
        <path d="M8.83333 6.33333H7.16666V8H8.83333V6.33333Z" fill="#0E79B7" />
      </svg>
    ),
  },
]);
