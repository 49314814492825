import { useContext, useEffect } from "react";
import useConcepts from "../../../hooks/api/useConcepts";
import Table from "../Table";
import { Link, useNavigate } from "react-router-dom";
import routes, { getUrl } from "../../../utils/routes";
import AppContext from "../../../contexts/AppContext";

function Submissions(props) {
  const { submissions, getSubmissions } = useConcepts();
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const route = () =>
    navigate(getUrl(routes.app.submissions.baseUrl) + routes.new);

  useEffect(() => {
    getSubmissions(user.role);
  }, []);
  return (
    <div>
      <Table
        showSearch={false}
        btn={{ title: "ADD", onClick: route }}
        title="Submissions"
        head={head}
        data={submissions}
      />
    </div>
  );
}

export default Submissions;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Name",
    target: "name",
  },
  {
    title: "Description",
    target: "description",
    render: (v) => v.slice(0, 60) + "...",
  },
  {
    title: "Compensation",
    target: "compensation",
  },
  {
    title: "Status",
    target: "status",
    render: (v) => <span className={`status ${v}`}>{v}</span>,
  },
  {
    title: "",
    target: "*",
    render: (v) => (
      <Link
        to={getUrl(routes.app.submissions.baseUrl + routes.details)}
        state={{ submission: v }}
      >
        view
      </Link>
    ),
  },
];
