import { useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import store from "../../utils/store";

function ConfigureCart(props) {
  const [saved, setSaved] = useState(store.getItem("kiosk"));
  const [id, setID] = useState(store.getItem("kiosk"));
  const save = () => {
    store.setItem("kiosk", id);
    setSaved(id);
    setID(null);
  };
  const clear = () => {
    store.removeItem("kiosk");
    setID(null);
    setSaved(null);
  };

  return (
    <div>
      <h2>Configure iCart: {saved}</h2>
      <br />
      <Input value={id} onChange={setID} placeholder="iCart ID" />
      <div className="btns">
        <Button
          onClick={save}
          disabled={!id}
          className="btn-submit"
          title="Save"
        />
        <br />
        <Button
          onClick={clear}
          style={{ marginLeft: "auto" }}
          className="btn-submit false"
          title="Clear ID"
        />
      </div>
    </div>
  );
}

export default ConfigureCart;
