import { FormInput, Submit, Form, FormSelect } from "../../components/form";
import { registerSchema } from "../../validators/auth";
import useAuth from "../../hooks/api/useAuth";
import "../../css/auth.css";
import { Link } from "react-router-dom";
import routes from "../../utils/routes";
import { Group } from "../submissions/SubmitConcept";
import { useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import ImagePicker from "../../components/ImagePicker";
import Select from "../../components/Select";
import CurrencyInput from "../../components/CurrencyInput";
import {
  excludeFromObject,
  objectToFormData,
  selectFromObject,
} from "../../utils";
import { toast } from "react-toastify";
import CheckBox from "../../components/CheckBox";

const SocialLink = ({ remove, id, link, platform, handleChange }) => {
  return (
    <div className="inputs items-list justify-between">
      <Input
        value={platform}
        placeholder="Platform"
        onChange={(e) => handleChange(id, "name", e)}
      />
      <Input
        value={link}
        placeholder="link"
        onChange={(e) => handleChange(id, "link", e)}
      />
      <Button onClick={remove} title="Remove" className="btn-delete" />
    </div>
  );
};

function Register(props) {
  const [isOpened, setIsOpened] = useState(false);
  const [haveAccept, setHaveAccept] = useState(false);
  const [haveSoldFood, setHaveSoldFood] = useState("");
  const [salesMedium, setSalesMedium] = useState("");
  const [servingPoint, setServingPoint] = useState("");
  const [sellingTime, setSellingTime] = useState("");
  const [brand, setBrand] = useState("");
  const [revenue, setRevenue] = useState(0);
  const [unitSold, setUnitSold] = useState(0);
  const [role, setRole] = useState("creator");
  const [food, setFood] = useState("");
  const [images, setImages] = useState([]);
  const [links, setLinks] = useState([
    {
      id: 1,
      link: "",
      platform: "Instagram",
    },
    {
      id: 2,
      link: "",
      platform: "Facebook",
    },
    {
      id: 3,
      link: "",
      platform: "YouTube",
    },
    {
      id: 4,
      link: "",
      platform: "TikTok",
    },
  ]);
  const { createAccount, isLoading, post } = useAuth();

  const handleSubmit = (data) => {
    let answers = {};
    const screenshots = [...Object.values(images)];
    data = excludeFromObject(["haveSold", "medium", "serving"], data);

    if (role === "creator") {
      answers["links"] = links.map((l) => excludeFromObject(["id"], l));
      answers["brand"] = brand;
      answers["bestSellingTime"] = sellingTime;
      answers["revenueGenerated"] = revenue;
      answers["servingPoint"] = servingPoint;
      answers["salesMedium"] = salesMedium;
      answers["unitSold"] = unitSold;
      answers["haveSoldFood"] = JSON.parse(haveSoldFood);
      answers["interestedFood"] = food;
    }

    if (role === "creator" && !screenshots.length)
      return toast.error("Upload atlease 3 screenshots of dashboard");
    if (screenshots.length) {
      answers = objectToFormData(answers);
      screenshots.forEach((file) => {
        answers.append("screenshots", file);
      });
    }
    createAccount(data, async (tokens) => {
      await post("/v1/temp/answers", answers, {
        headers: {
          "x-access-token": tokens.accessToken,
        },
      });
    });
  };

  return (
    <div className={`login-container raleway ${role}`}>
      <Form
        validationSchema={registerSchema}
        onSubmit={handleSubmit}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          role: "creator",
          password: "",
          haveSold: "",
          medium: "",
          serving: "",
        }}
      >
        <div className="form-container flex justify-center align-center">
          <div className="card form">
            <div className="inputs">
              <FormInput name="firstName" placeholder="First Name" />
              <FormInput name="lastName" placeholder="Last Name" />
            </div>
            <FormInput name="email" placeholder="Email" />
            <FormInput name="phoneNumber" placeholder="Phone Number" />
            <FormSelect
              onChangeListener={setRole}
              placeholder={"Role"}
              options={[
                { value: "creator", label: "Creator" },
                { value: "operator", label: "Operator" },
                { value: "supplier", label: "Supplier" },
              ]}
              initialValue="creator"
              name="role"
            />
            {role === "creator" && (
              <>
                <br />
                <Input
                  value={brand}
                  onChange={setBrand}
                  placeholder="Brand Name"
                />
                <br />
                <Group
                  items={links}
                  setItems={setLinks}
                  Element={SocialLink}
                  title="Social Media Links"
                />
                <br />
                <div className="imgs d-screenshot">
                  <div className="img-cn">
                    <ImagePicker
                      // multiple
                      // onSelect={(e) => setImages([...e])}
                      onSelect={(one) => setImages({ ...images, one })}
                      placeholder="Dashboard Screenshot"
                    />
                  </div>
                  <div className="img-cn">
                    <ImagePicker
                      onSelect={(two) => setImages({ ...images, two })}
                      placeholder="Dashboard Screenshot"
                    />
                  </div>
                  <div className="img-cn">
                    <ImagePicker
                      onSelect={(three) => setImages({ ...images, three })}
                      placeholder="Dashboard Screenshot"
                    />
                  </div>
                  <div className="img-cn">
                    <ImagePicker
                      onSelect={(four) => setImages({ ...images, four })}
                      placeholder="Dashboard Screenshot"
                    />
                  </div>
                </div>
                <br />
                <h3 className="t-blue">
                  If you want to be a food business owner, what food would you
                  choose?
                </h3>
                <br />
                <Input value={food} onChange={setFood} placeholder="Answer" />
                <br />
                {food && (
                  <>
                    <h3 className="t-blue">Have you sold {food} before?</h3>
                    <br />
                    <FormSelect
                      name="haveSold"
                      onChangeListener={setHaveSoldFood}
                      placeholder={"Answer"}
                      options={[
                        { value: "true", label: "Yes" },
                        { value: "false", label: "No" },
                      ]}
                      initialValue={"no"}
                    />
                    {haveSoldFood === "true" && (
                      <>
                        <br />
                        <CurrencyInput
                          value={unitSold}
                          onChange={setUnitSold}
                          placeholder="Unit sold"
                        />
                        <FormSelect
                          name="medium"
                          onChangeListener={setSalesMedium}
                          placeholder={"Sales Medium"}
                          options={[
                            { value: "pickup", label: "Pickup" },
                            { value: "delivery", label: "Delivery" },
                            { value: "event", label: "Event" },
                            { value: "restaurant", label: "Restaurant" },
                            { value: "other", label: "Other" },
                          ]}
                          initialValue="yes"
                        />
                        <br />
                        <FormSelect
                          name="serving"
                          onChangeListener={setServingPoint}
                          placeholder={"Serving Point"}
                          options={[
                            { value: "home", label: "Home" },
                            { value: "restaurant", label: "Restaurant" },
                            { value: "cloud", label: "Cloud Kitchen" },
                            { value: "other", label: "Other" },
                          ]}
                          initialValue="yes"
                        />
                        <br />
                        <h3 className="t-blue">
                          How much did you generate out of these orders?
                        </h3>
                        <br />
                        <CurrencyInput
                          value={revenue}
                          onChange={setRevenue}
                          placeholder="Answer"
                        />
                        <br />
                        <h3 className="t-blue">Your food best selling time</h3>
                        <br />
                        <Input
                          value={sellingTime}
                          onChange={setSellingTime}
                          placeholder="Answer"
                        />
                        <br />
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <br />
            <FormInput name="password" placeholder="Password" isPassword />
            {role === "creator" && (
              <span onClick={() => setIsOpened(!isOpened)} className="terms">
                {isOpened ? "Close" : "Open"} iCart Creator Terms and Conditions
              </span>
            )}
            {isOpened && (
              <div className="terms-and-conditions">
                <h2>iCart Creator Terms and Conditions</h2>
                <br />
                <p>
                  Welcome to iCart! We're thrilled to have you join our
                  community of culinary creators. Before you get started, please
                  take a moment to review our terms and conditions:
                </p>
                <ol style={{ padding: 30 }}>
                  <li>Non-CompeteClause:</li>
                  <p>
                    ● Creators are not permitted to operate a similar culinary
                    concept, whether under the same name or a different one,
                    outside of the iCart platform.
                  </p>
                  <br />
                  <li>ExclusivityAgreement:</li>
                  <p>
                    ● Products sold through iCart outlets are strictly
                    prohibited from being sold outside of the platform, even if
                    the creator owns a separate restaurant or establishment.
                  </p>
                  <br />

                  <li>SocialMediaMarketingRequirement:</li>
                  <p>
                    ● Creators are required to actively market their brand and
                    products through their own social media channels to enhance
                    visibility and engagement.
                  </p>
                  <br />
                  <li>SocialMediaContentCreation:</li>
                  <p>
                    ● Creators must create dedicated social media channels for
                    their brand and collaborate with our media team to produce a
                    minimum of 15 posts per month, along with daily stories,
                    showcasing their culinary creations and iCart experience.
                  </p>
                  <br />
                  <li>FinancialSharingFormula:</li>
                  <p>
                    ● Creators will receive a share of 10% of the revenue
                    generated through their culinary concepts at the end of each
                    month. However, this revenue share is contingent upon the
                    creator fulfilling their obligations within the partnership.
                  </p>
                  <br />
                  <li>AnnualContractandOwnershipRights:</li>
                  <p>
                    ● Each creator's partnership with iCart is subject to an
                    annual contract, which may be renewed upon mutual agreement.
                    In the event of any breach of contract, including disclosure
                    of proprietary information, iCart retains full ownership of
                    the concept, and the creator forfeits the right to use the
                    brand's name outside of iCart.
                  </p>
                  <br />
                  <li>ConceptDemonstrationRequirement:</li>
                  <p>
                    ● Upon approval, creators are expected to conduct concept
                    demonstrations in the iCart kitchen to ensure alignment with
                    the platform's standards and offerings.
                  </p>
                  <br />
                </ol>
                <p>
                  By agreeing to these terms and conditions, creators
                  acknowledge their commitment to upholding the standards and
                  integrity of the iCart platform and agree to abide by the
                  outlined requirements and obligations.
                </p>
                <br />
                <p>
                  Please contact us if you have any questions or concerns
                  regarding these terms. We look forward to a successful
                  partnership together!
                </p>
                <br />
                <span style={{ fontWeight: 800 }}>
                  [Date: April 28, 2024]
                </span>{" "}
              </div>
            )}
            <br />
            {role === "creator" && (
              <div className="flex align-center">
                <CheckBox onChange={setHaveAccept} />
                <span style={{ marginLeft: 5 }}>
                  I have read and accept the terms and condtions.
                </span>
              </div>
            )}
            <Submit
              disabled={isLoading || (role === "creator" && !haveAccept)}
              loading={isLoading}
              onClick={createAccount}
              className="login-submit flex justify-center align-center"
              title="Register"
            />
            <br />
            <div className="flex justify-center">
              <Link to={routes.auth.login} className="t-blue t-small">
                Already have an account?
                <span className="t-blue f700 "> Login</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="footer f400 flex justify-center align-center">
          Copyright © 2023 <span className="t-blue f700">iCart</span>. All
          rights reserved
        </div>
      </Form>
    </div>
  );
}

export default Register;
