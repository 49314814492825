import { useState } from "react";
import ImagePicker from "../../components/ImagePicker";
import useProfile from "../../hooks/api/useProfile";
import Button from "../../components/Button";

function ChangePicture(props) {
  const [image, setImage] = useState(null);
  const { changeImage, isLoading } = useProfile();
  return (
    <div>
      <ImagePicker onSelect={setImage} />
      <Button
        title="Update"
        loading={isLoading}
        onClick={() => changeImage(image)}
        className="btn-submit"
      />
    </div>
  );
}

export default ChangePicture;
