import { useState } from "react";
import Button from "../../components/Button";
import useLocation from "../../hooks/useLcoation";

function Debug(props) {
  const { position, getLocation, resetLocation, locationData } = useLocation();
  const [showData, setShowData] = useState(null);
  return (
    <div>
      <h2>Debug</h2>
      <h2>{JSON.stringify({ position })}</h2>
      <br />
      <div className="flex">
        <Button onClick={getLocation} className="btn-submit" title="Get" />
        <Button onClick={resetLocation} title="Reset" className="btn-submit" />
        <Button
          onClick={() => setShowData(!showData)}
          title="Show data"
          className="btn-submit"
        />
      </div>
      <br />
      {showData && <p>{JSON.stringify({ locationData })}</p>}
    </div>
  );
}

export default Debug;
