import Dashboard from "../../pages/sales/Dashboard";
import { Routes, Route } from "react-router-dom";
import routes from "../../utils/routes";
import New from "./New";

function Sales(props) {
  return (
    <Routes>
      <Route path={routes.baseUrl} element={<Dashboard />} />
      <Route path={routes.new} element={<New />} />
    </Routes>
  );
}

export default Sales;
