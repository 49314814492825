import { FormInput, Submit, Form } from "../../components/form";
import { loginSchema } from "../../validators/auth";
import useAuth from "../../hooks/api/useAuth";
import "../../css/auth.css";
import routes, { links } from "../../utils/routes";
import urls from "../../api/urls";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import store from "../../utils/store";
import { RenderUser } from "../../components/Header";

function Login(props) {
  const [accounts, setAccounts] = useState([]);
  const { login, isLoading } = useAuth();

  useEffect(() => {
    const users = store.getItem("accounts") || [];
    setAccounts(users);
  }, []);
  return (
    <div className="login-container raleway">
      <Form
        validationSchema={loginSchema}
        onSubmit={login}
        initialValues={{ email: "", password: "" }}
      >
        <div className="form-container flex justify-center align-center">
          <div className="card form">
            <FormInput name="email" placeholder="Email or ID" />
            <FormInput name="password" placeholder="Password" isPassword />
            {accounts.length ? (
              <div>
                <span style={{ fontSize: 12 }}>Continue as: </span>
                {accounts.map((_, idx) => (
                  <RenderUser
                    user={_}
                    key={idx}
                    name={`${_.firstName} ${_.lastName}`}
                    email={_.email}
                    img={_.profileImage}
                  />
                ))}
              </div>
            ) : null}
            <Submit
              disabled={isLoading}
              loading={isLoading}
              onClick={login}
              className="login-submit flex justify-center align-center"
              title="Login"
            />
            <br />
            <div className="flex justify-center">
              <Link to={routes.auth.register} className="t-blue t-small">
                Don't have an account?
                <span className="t-blue f700 "> Register</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="footer f400 flex justify-center align-center">
          Copyright © 2023 <span className="t-blue f700">iCart</span>. All
          rights reserved
        </div>
      </Form>
    </div>
  );
}

export default Login;
