import { useState } from "react";
import CurrencyInput from "../../components/CurrencyInput";
import Button from "../../components/Button";
import useWallet from "../../hooks/api/useWallet";

function TopUp(props) {
  const [amount, setAmount] = useState(null);
  const { initFund, isLoading } = useWallet();
  return (
    <div>
      <h2 className="t-blue">Wallet Top Up</h2>
      <br />
      <hr />
      <br />
      <CurrencyInput
        placeholder="Enter amount"
        value={amount}
        onChange={setAmount}
      />
      <Button
        loading={isLoading}
        onClick={() => initFund(amount)}
        disabled={!amount}
        title="Continue"
        className="btn-submit"
      />
    </div>
  );
}

export default TopUp;
