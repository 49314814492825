import React, { useEffect, useRef, useState } from "react";

const AudioPlayer = ({ audioSrc, isPlaying, setIsPlaying }) => {
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef(null);

  const playAudio = () => {
    setPlaying(true);
    audioRef.current.play();
  };

  const pauseAudio = () => {
    setPlaying(false);
    audioRef.current.pause();
  };

  useEffect(() => {
    const handlePause = () => {
      setIsPlaying(false);
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    const audioElement = audioRef.current;
    audioElement.addEventListener("pause", handlePause);
    audioElement.addEventListener("ended", handleEnded);

    return () => {
      audioElement.removeEventListener("pause", handlePause);
      audioElement.removeEventListener("ended", handleEnded);
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      pauseAudio();
      playAudio();
    } else {
      pauseAudio();
    }
  }, [isPlaying]);

  return (
    <div className="audio-player">
      {playing && <button onClick={() => setIsPlaying(false)}>PAUSE</button>}
      <audio hidden loop ref={audioRef} src={audioSrc} />
    </div>
  );
};

export default AudioPlayer;
