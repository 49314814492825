import ICartSales from "../../components/table/active/ICartSales";
import Sales from "../../components/table/active/Sales";
import AppContext from "../../contexts/AppContext";
import { useContext } from "react";

function Dashboard(props) {
  const { user } = useContext(AppContext);
  return <div>{user.role === "investor" ? <ICartSales /> : <Sales />}</div>;
}

export default Dashboard;
