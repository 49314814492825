import { Form, FormInput, Submit } from "../../components/form";
import useWallet from "../../hooks/api/useWallet";
import { excludeFromObject } from "../../utils";
import { toast } from "react-toastify";
import { changePinSchema } from "../../validators/accounts";

function ChangePin(props) {
  const { changePin, isLoading } = useWallet();
  const handleChangePin = (data) => {
    if (data.pin !== data.pin2) return toast.warn("Pin not matching");
    changePin(excludeFromObject(["pin2"], data));
  };
  return (
    <div>
      <h2 className="t-blue">Change Transaction Pin</h2>
      <br />
      <br />
      <Form
        onSubmit={handleChangePin}
        validationSchema={changePinSchema}
        initialValues={{ oldPin: "", pin: "", pin2: "" }}
      >
        <FormInput isPassword name="oldPin" placeholder="Current Pin" />
        <div className="inputs">
          <FormInput isPassword name="pin" placeholder="New pin" />
          <FormInput isPassword name="pin2" placeholder="Confirm new pin" />
        </div>
        <Submit loading={isLoading} title="Save" className="btn-submit" />
      </Form>
    </div>
  );
}

export default ChangePin;
