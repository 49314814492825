import React, { useEffect, useState } from "react";
import { FormInput, Form, Submit, FormSelect } from "../../components/form";
import ImagePicker from "../../components/ImagePicker";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { excludeFromObject } from "../../utils/func";
import { conceptSubmission } from "../../validators/icart";
import useConcepts from "../../hooks/api/useConcepts";
import Select from "../../components/Select";
import neutrients from "../../utils/neutrients";
import store from "../../utils/store";

const Machine = ({
  remove,
  name,
  id,
  handleChange,
  model,
  dimension,
  quantity,
  icart,
}) => {
  return (
    <div
      className={`inputs items-list ${
        icart ? `items-list-4` : "items-list-3"
      } justify-between`}
    >
      <Input
        value={name}
        placeholder="Name"
        onChange={(e) => handleChange(id, "name", e)}
      />
      <Input
        placeholder="Model"
        value={model}
        onChange={(e) => handleChange(id, "model", e)}
      />
      <Input
        placeholder="Quantity"
        value={quantity}
        onChange={(e) => handleChange(id, "quantity", e)}
      />
      {icart && (
        <>
          <Input
            value={dimension}
            placeholder="Dimension"
            onChange={(e) => handleChange(id, "dimension", e)}
          />
        </>
      )}
      <Button onClick={remove} title="Remove" className="btn-delete" />
    </div>
  );
};
const Instructions = ({ remove, id, name, handleChange }) => {
  return (
    <div className="inputs items-list items-list-1 justify-between">
      <Input
        value={name}
        placeholder="Name"
        onChange={(e) => handleChange(id, "name", e)}
      />
      <Button onClick={remove} title="Remove" className="btn-delete" />
    </div>
  );
};

const Menu = ({ remove, id, description, name, handleChange }) => {
  return (
    <div className="inputs items-list justify-between">
      <Input
        value={name}
        placeholder="Name"
        onChange={(e) => handleChange(id, "name", e)}
      />
      <Input
        value={description}
        placeholder="Description (Optional)"
        onChange={(e) => handleChange(id, "description", e)}
      />
      <Button onClick={remove} title="Remove" className="btn-delete" />
    </div>
  );
};

const Ingredient = ({ remove, name, specification, id, handleChange }) => {
  return (
    <div className="inputs items-list justify-between">
      <Input
        value={name}
        placeholder="Name"
        onChange={(e) => handleChange(id, "name", e)}
      />
      <Input
        value={specification}
        placeholder="Specification"
        onChange={(e) => handleChange(id, "specification", e)}
      />
      <Button onClick={remove} title="Remove" className="btn-delete" />
    </div>
  );
};

const Recipe = ({
  remove,
  id,
  handleChange,
  unit,
  instruction,
  ingredients,
  ingredient,
}) => {
  return (
    <div className="inputs items-list items-list-3 justify-between">
      <Select
        value={ingredient}
        onSelect={(v) => handleChange(id, "ingredient", v)}
        placeholder="Ingredient"
        options={ingredients.map((i) => ({
          label: i.name,
          value: i.name,
        }))}
      />
      <Input
        value={unit}
        placeholder="Unit (g)"
        onChange={(e) => handleChange(id, "unit", e)}
      />
      <Input
        value={instruction}
        placeholder="Instructions"
        onChange={(e) => handleChange(id, "instruction", e)}
      />
      <Button onClick={remove} title="Remove" className="btn-delete" />
    </div>
  );
};

const Material = ({ remove, id, handleChange }) => {
  return (
    <div className="inputs items-list items-list-4 justify-between">
      <Input placeholder="Name" onChange={(e) => handleChange(id, "name", e)} />
      <Input placeholder="Unit" onChange={(e) => handleChange(id, "unit", e)} />
      <Input
        placeholder="Quantity"
        onChange={(e) => handleChange(id, "quantity", e)}
      />
      <Input
        placeholder="Price"
        onChange={(e) => handleChange(id, "price", e)}
      />
      <Button onClick={remove} title="Remove" className="btn-delete" />
    </div>
  );
};

const Nutrient = ({ remove, id, nutrient, mass, percentage, handleChange }) => {
  return (
    <div className="inputs items-list items-list-3 justify-between">
      <Select
        value={nutrient}
        options={neutrients}
        placeholder="Nutrient"
        onSelect={(e) => handleChange(id, "nutrient", e)}
      />
      <Input
        type="number"
        value={mass}
        placeholder="Mass (g)"
        onChange={(e) => handleChange(id, "mass", e)}
      />
      <Input
        type="number"
        value={percentage}
        placeholder="Percentage"
        onChange={(e) => handleChange(id, "percentage", e)}
      />
      <Button onClick={remove} title="Remove" className="btn-delete" />
    </div>
  );
};

export const Group = ({ title, Element, items, setItems, ...props }) => {
  const handleChange = (id, property, value) => {
    const updated = items.map((i) => {
      if (i.id === id) i[property] = value;
      return i;
    });
    setItems(updated);

    // const index = items.findIndex((item) => item.id === id);
    // const updatedMachine = {
    //   ...items[index],
    //   [property]: value,
    // };
    // const updatedItems = [...items];
    // updatedItems[index] = updatedMachine;
    // setItems(updatedItems);
  };
  return (
    <>
      <h3 className="t-blue">{title}</h3>
      <br />
      <div className="flex items mb-gr">
        <div className="list">
          {items.map((i, idx) => (
            <Element
              key={idx}
              {...i}
              {...props}
              handleChange={handleChange}
              remove={() => setItems(items.filter((_) => _.id !== i.id))}
            />
          ))}
        </div>
        <Button
          onClick={() =>
            setItems([
              ...items,
              { id: items.length ? items[items.length - 1].id + 1 : 1 },
            ])
          }
          className="btn-100 klo"
          title="ADD"
        />
      </div>
    </>
  );
};

const storeItem = (name, value) => {
  if (!value || !value.length) return store.setItem(name, null);
  if (Object.keys(value[0]).length > 1) store.setItem(name, value);
};

const getItem = (name) => store.getItem(name) || [{ id: 1 }];

const SubmitConcept = () => {
  const [ingredients, setIngredients] = useState(getItem("ingredients"));
  const [recipe, setRecipe] = useState(getItem("recipe"));
  const [menus, setMenus] = useState(getItem("menus"));
  const [prep, setPrep] = useState(getItem("prep"));
  const [machines, setMachines] = useState(getItem("machines"));
  const [machines2, setMachines2] = useState(getItem("machines2"));
  const [nutrientions, setNutrientions] = useState(getItem("nutrientions"));
  const [images, setImages] = useState({});
  const [packagingImages, setPackagingImages] = useState({});

  const { isLoading, addRequest } = useConcepts();

  useEffect(() => {
    storeItem("ingredients", ingredients);
  }, [ingredients]);

  useEffect(() => {
    storeItem("recipe", recipe);
  }, [recipe]);

  useEffect(() => {
    storeItem("menus", menus);
  }, [menus]);

  useEffect(() => {
    storeItem("prep", prep);
  }, [prep]);

  useEffect(() => {
    storeItem("machines", machines);
  }, [machines]);

  useEffect(() => {
    storeItem("machines2", machines2);
  }, [machines2]);

  useEffect(() => {
    storeItem("nutrientions", nutrientions);
  }, [nutrientions]);

  return (
    <div>
      <h2 className="t-blue">Submit new Concept</h2>
      <br />
      <Form
        validationSchema={conceptSubmission}
        initialValues={
          store.getItem("form") || {
            description: "",
            packaging: "",
            compensation: "",
            name: "",
            origin: "",
            bestEatingTime: "",
            bestServeTo: "",
            expiring: "",
          }
        }
        onSubmit={(values) => {
          addRequest(
            {
              ...values,
              prepMachines: machines.map((m) => excludeFromObject(["id"], m)),
              icartMachines: machines2.map((m) => excludeFromObject(["id"], m)),
              menuItems: menus.map((m) => excludeFromObject(["id"], m)),
              ingredients: ingredients.map((m) => excludeFromObject(["id"], m)),
              prep: prep.map((m) => m.name),
              recipe: recipe.map((m) => excludeFromObject(["id"], m)),
              healthScore: nutrientions.map((m) =>
                excludeFromObject(["id"], m)
              ),
            },
            [...Object.values(images)],
            [...Object.values(packagingImages)]
          );
        }}
      >
        <FormInput name="name" placeholder="Concept Name" />

        <div className="imgs">
          <div className="img-cn">
            <ImagePicker
              // multiple
              // onSelect={(e) => setImages([...e])}
              onSelect={(one) => setImages({ ...images, one })}
              placeholder="Upload an Images"
            />
          </div>
          <div className="img-cn">
            <ImagePicker
              onSelect={(two) => setImages({ ...images, two })}
              placeholder="Upload an Image"
            />
          </div>
          <div className="img-cn">
            <ImagePicker
              onSelect={(three) => setImages({ ...images, three })}
              placeholder="Upload an Image"
            />
          </div>
          <div className="img-cn">
            <ImagePicker
              onSelect={(four) => setImages({ ...images, four })}
              placeholder="Upload an Image"
            />
          </div>
        </div>
        <br />
        <FormInput name="origin" placeholder="Concept Origin" />
        <FormInput name="description" textarea placeholder="Description" />
        <FormInput name="bestEatingTime" placeholder="Best eating time" />
        <FormSelect
          name="bestServeTo"
          placeholder="Best serve to"
          options={[
            {
              label: "Adult",
              value: "adult",
            },
            {
              label: "Child",
              value: "child",
            },
            {
              label: "Teen",
              value: "teen",
            },
            {
              label: "Adult & Child",
              value: "adult-child",
            },
            {
              label: "Adult & Teen",
              value: "adult-teen",
            },
            {
              label: "Child & Teen",
              value: "child-teen",
            },
            {
              label: "All ages",
              value: "all-ages",
            },
          ]}
        />
        <br />
        <Group
          items={menus}
          setItems={setMenus}
          Element={Menu}
          title="Menu Items"
        />
        <br />
        <Group
          items={ingredients}
          setItems={setIngredients}
          Element={Ingredient}
          title="Ingredients"
        />
        <br />
        <Group
          ingredients={ingredients}
          items={recipe}
          setItems={setRecipe}
          Element={Recipe}
          title="Recipe"
        />
        <br />
        <Group
          items={prep}
          setItems={setPrep}
          Element={Instructions}
          title="Prep Instructions"
        />
        <br />
        <FormInput name="packaging" placeholder="Mode of Packaging" />
        <br />
        <div className="imgs">
          <div className="img-cn">
            <ImagePicker
              onSelect={(one) =>
                setPackagingImages({ ...packagingImages, one })
              }
              placeholder="Upload Packaging Image"
            />
          </div>
          <div className="img-cn">
            <ImagePicker
              onSelect={(two) =>
                setPackagingImages({ ...packagingImages, two })
              }
              placeholder="Upload Packaging Image"
            />
          </div>
          <div className="img-cn">
            <ImagePicker
              onSelect={(three) =>
                setPackagingImages({ ...packagingImages, three })
              }
              placeholder="Upload Packaging Image"
            />
          </div>
          <div className="img-cn">
            <ImagePicker
              onSelect={(four) =>
                setPackagingImages({ ...packagingImages, four })
              }
              placeholder="Upload Packaging Image"
            />
          </div>
        </div>
        <br />
        <Group
          items={machines}
          setItems={setMachines}
          Element={Machine}
          title="Prep Machineries"
        />
        <br />
        <Group
          items={machines2}
          setItems={setMachines2}
          Element={Machine}
          icart
          title="iCart Machineries"
        />
        <br />
        <FormInput
          type="number"
          name="expiring"
          placeholder="Expiration date (days)"
        />
        <br />
        <Group
          items={nutrientions}
          setItems={setNutrientions}
          Element={Nutrient}
          title="Health Score"
        />
        <br />
        <FormSelect
          name="compensation"
          placeholder="Compensation"
          options={[
            {
              label: "Outright",
              value: "outright",
            },
            {
              label: "Royalty",
              value: "royalty",
            },
          ]}
        />
        {/* <Group
          items={machines}
          setItems={setMachines}
          Element={Machine}
          title="Machineries"
        />
        <br /> */}
        <br />
        <Submit
          onChange={(v) => store.setItem("form", v)}
          loading={isLoading}
          title="Submit"
          className="btn-submit"
        />
        <br />
        <br />
        <br />
      </Form>
    </div>
  );
};

export default SubmitConcept;
