import React, { useContext } from "react";
// import Button from "../../components/Button";
// import { useNavigate } from "react-router-dom";
// import routes from "../../utils/routes";
import recent from "../../assets/svgs/recent.svg";
import booked from "../../assets/svgs/booked.svg";
import checkedIn from "../../assets/svgs/checked-in.svg";
// import available from "../../assets/svgs/available.svg";
import Revenue from "../../components/charts/Revenue";
import Submissions from "../../components/table/active/Submissions";
import AppContext from "../../contexts/AppContext";
import Sales from "../../components/table/active/Sales";
import { currencyFormatter } from "../../utils/func";
import ICartSales from "../../components/table/active/ICartSales";

const Card = ({ title, svg, value = 0 }) => (
  <div className="hover flex justify-center align-center s-card">
    <div className="icon">
      <img src={svg} alt={title} />
    </div>
    <h2 className="t-blue">{value}</h2>
    <span>{title}</span>
  </div>
);

const Dashboard = () => {
  const { user } = useContext(AppContext);
  return (
    <div>
      <div className="stats flex">
        <div className="stat flex bookings-stat">
          <Card
            value={
              user.role === "investor" ? 50 : user.role === "operator" ? 30 : 0
            }
            svg={recent}
            title={
              user.role === "operator" || user.role === "investor"
                ? "Target Sales"
                : "Pending Submissions"
            }
          />
          <Card
            svg={booked}
            title={
              user.role === "investor"
                ? "Current Sales"
                : user.role === "operator"
                ? "Today's Sales"
                : "Active Submissions"
            }
          />
          <Card
            svg={booked}
            value={
              user.role === "investor" ? currencyFormatter(125000, "$") : 0
            }
            title={
              user.role === "investor"
                ? "Investment"
                : user.role === "operator"
                ? "Total Sales"
                : "Total Submissions"
            }
          />
          <Card
            value={
              user.role === "investor"
                ? currencyFormatter(0, "$")
                : user.role === "operator"
                ? "0%"
                : 0
            }
            svg={user.role === "investor" ? booked : checkedIn}
            title={
              user.role === "investor"
                ? "Generated Income"
                : user.role === "operator"
                ? "Performance"
                : "Exited Concepts"
            }
          />
        </div>
        <Revenue operator={user.role === "operator"} />
        {/* <div className="stat">
          <h4 className="t-primary">Today's Staff Schedule</h4>
          <br />
          <Table showSearch={false} head={head} />
        </div> */}
      </div>
      <br />
      <br />
      {user.role === "investor" ? (
        <ICartSales />
      ) : user.role === "operator" ? (
        <Sales />
      ) : user.role === "creator" || user.role === "school" ? (
        <Submissions />
      ) : null}
    </div>
  );
};
export default Dashboard;
