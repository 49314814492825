import client from "../api";
import urls from "../api/urls";

export const excludeFromObject = (fields = [], obj) => {
  const output = {};
  Object.keys(obj).forEach((f) => {
    if (!fields.includes(f)) {
      output[f] = obj[f];
    }
  });
  return output;
};

export const selectFromObject = (fields = [], obj) => {
  const output = {};
  fields.forEach((f) => {
    output[f] = obj[f];
  });
  return output;
};

export function objectToFormData(
  obj,
  formData = new FormData(),
  parentKey = null
) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const fullKey = parentKey ? `${parentKey}[${key}]` : key;

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        objectToFormData(value, formData, fullKey);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          objectToFormData(item, formData, `${fullKey}[${index}]`);
        });
      } else {
        formData.append(fullKey, value);
      }
    }
  }

  return formData;
}

export const legalUrls = {
  terms: "https://geticart.com/legal/terms-and-conditions",
  privacy: "https://geticart.com/legal/privacy-policy",
};

export const tabNames = {
  home: "Home",
  settings: "Settings",
  apls: "APLS",
  management: "Management",
  wallet: "Wallet",
};

export const numberFormatter = (n, pre = "₦") =>
  pre + n?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getPayUrl = (url) => {
  while (url.includes("|")) {
    url = url.replace("|", "%7C");
  }
  return url;
};

export const getImageUrl = (url) =>
  url
    ? url?.includes("http") || url?.includes("file://")
      ? url
      : client.getUri() + urls.files.baseUrl + url
    : null;

export function formatDate(date) {
  date = new Date(date);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // If the date is in the future, return tomorrow or the day and month with year.
  if (date > now) {
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (date.toDateString() === tomorrow.toDateString()) {
      return "Tomorrow";
    } else {
      const day = days[date.getDay()];
      const dayOfMonth = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${dayOfMonth}${getDaySuffix(dayOfMonth)} ${month} ${year}`;
    }
  }

  // If the date is within the last minute, return 'just now'.
  if (diffInSeconds <= 10) {
    return "Just now";
  }
  if (diffInSeconds <= 60) {
    const secondsAgo = Math.floor(diffInSeconds);
    return `${secondsAgo}s ago`;
  }

  // If the date is within the last hour, return the time difference in seconds.
  if (diffInSeconds <= 3600) {
    const secondsAgo = Math.floor(diffInSeconds / 60);
    return `${secondsAgo}m ago`;
  }
  if (diffInSeconds <= 3600 * 24) {
    const secondsAgo = Math.floor(diffInSeconds / 3600);
    return `${secondsAgo}h ago`;
  }

  // If the date is within the last 24 hours (but not today), return 'Yesterday at HH:mm'.
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);
  if (date.toDateString() === yesterday.toDateString()) {
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    return `Yesterday at ${hours}:${minutes}`;
  }

  // For dates older than yesterday, format like 'Monday 24th July 2023'.
  const day = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${dayOfMonth}${getDaySuffix(dayOfMonth)} ${month} ${year}`;
}

// Helper function to add a suffix to the day of the month (e.g., 1st, 2nd, 3rd, 4th).
function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  const lastDigit = day % 10;
  if (lastDigit === 1) {
    return "st";
  } else if (lastDigit === 2) {
    return "nd";
  } else if (lastDigit === 3) {
    return "rd";
  } else {
    return "th";
  }
}

// Helper function to add a leading zero to single-digit numbers.
function padZero(number) {
  return number < 10 ? `0${number}` : number;
}

export const transactionStatuses = { success: "success", pending: "pending" };

export const COLORS = Object.freeze({
  MAIN: {
    // BLUE: "#F7941D",
    // GRAY_BLACK: "#1A1918",
    // BLUE: "#0E79B7",
    BLUE: "#dc8628",
    DARK: "#614f41",
    GRAY_BLACK: "#3A3A3C",
    LIGHT: "#FFF9F5",
    YELLOW: "#dc8628",
    LIGHT02: "#614f4133",
    LIGHT03: "#614f41",
  },
  NEUTRALS: {
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    // TEXT_WHITE: "#FDEAD2",
    // BACKGROUND_WHITE: "#FFF9F2",
    TEXT_WHITE: "#D9DCDD",
    BACKGROUND_WHITE: "#EDEDED",
    TRANSPARENT: "transparent",
  },
  BLUE_TITNTS_SHADES: {
    // B_01: "#FCDBB4",
    // B_02: "#FAB868",
    // B_03: "#F8A643",
    // B_04: "#CE7B18",
    // B_05: "#7B4A0E",
    B_01: "#CFE4F1",
    B_02: "#86BCDB",
    B_03: "#368FC3",
    B_04: "#0C6598",
    B_05: "#073C5B",
  },
  CHARCOAL_TITNTS_SHADES: {
    C_01: "#D8D8D8",
    C_02: "#9C9C9D",
    C_03: "#5B5B5C",
    C_04: "#303032",
    C_05: "#1D1D1E",
  },
  WHITE_TINTS_SHADES: {
    W_01: "#E1E1E1",
    W_02: "#DBDBDB",
    W_03: "#C9C9C9",
    W_04: "#BCBCBC",
    W_05: "#A3A3A3",
  },
  ALERTS: {
    WARN: "#FF9800",
    INFO: "#2196F3",
    SUCCESS: "#4CAF50",
    DANGER: "#F44336",
  },
  BORDER: "#E6E7E9",
  TAB: "#edd5c2",
  // TAB: "#EDF6FC",
  BARS: {
    B_01: "#7ADE7E",
  },
});

export const TRANSACTION_ICONS_COLORS = Object.freeze({
  ICART_PURCHASE: "#BAE6FF",
  credit: "#5BC2FD",
  debit: "#D7D7D7",
});

export const BORDER_RADIUS = 8;
export const PADDING = 16;
export const PADDING_SCREEN = 24;
