import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";
import { getPayUrl } from "../../utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import store from "../../utils/store";

const useBusiness = () => {
  const [plans, setPlans] = useState([]);
  const [concepts, setConcepts] = useState([]);
  const { isLoading, get, post, patch } = useAPI();
  const [subscriptions, setSubscriptions] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [sales, setSales] = useState([]);
  const [onlineOrders, setOnlineOrders] = useState([]);
  const [kiosks, setKiosks] = useState([]);
  const [order, setOrder] = useState(null);
  const navigate = useNavigate();

  const getMySubscriptions = async (cb) => {
    const { data, error } = await get(urls.businesses.subscriptions.me);
    if (error || !data) return;
    setSubscriptions(data.data.subscriptions);
    if (typeof cb === "function") cb(data.data.subscriptions);
  };

  const getPlans = async () => {
    const { data, error } = await get(urls.businesses.subscriptions.plans);
    if (error) return;
    setPlans(data.data.plans);
  };

  const subscribe = async (plan, concept = [], initialLocation, cb) => {
    const { data, error } = await post(
      urls.businesses.subscriptions.subscribe,
      {
        management: "online",
        plan,
        concepts,
        initialLocation,
      }
    );
    if (error) return;
    window.open(getPayUrl(data.data.checkoutUrl), "_blank");
    if (typeof cb === "function") cb();
  };

  const getConcepts = async () => {
    const { data, error } = await get(urls.businesses.concepts);
    if (error) return;
    setConcepts(data.data.concepts);
  };

  const getInventory = async (subId) => {
    const { data, error } = await get(
      true,
      urls.businesses.inventory.get + subId
    );
    if (error || !data) return;
    setInventory(data.data.inventory);
  };

  const addSale = async (fdata, cb) => {
    fdata.kiosk = store.getItem("kiosk");
    let users = store.getItem("accounts") || [];
    const { data, error } = await post(urls.businesses.sales.baseUrl, {
      ...fdata,
      position: {
        latitude: 1,
        longitude: 1,
      },
      operators: users.map((u) => u._id),
    });
    if (error) return;
    toast.success(data.message);
    if (typeof cb === "function") cb();
    else navigate(-1);
  };
  const getSales = async () => {
    const { data, error } = await get(urls.businesses.sales.baseUrl);
    if (error) return;
    setSales(data.data.sales);
  };

  const getOnlineOrders = async (kiosk) => {
    const { data, error } = await get(
      urls.onlineOrders.orders + (kiosk || store.getItem("kiosk"))
    );
    if (error) return;
    setOnlineOrders(data.data.orders);
  };

  const getActiveKiosks = async () => {
    const { data, error } = await get(urls.onlineOrders.kiosks);
    if (error) return;
    setKiosks(data.data.kiosks);
  };

  const getOrder = async (id, searching) => {
    const { data, error } = await get(
      urls.onlineOrders.details.replace(":id", id)
    );
    if (error) return toast.warn(error.message);
    if (searching) {
      setOnlineOrders([data.data.order]);
    } else {
      setOrder(data.data.order);
      toast.success(data.message);
    }
  };

  const completeOrder = async (_id, id) => {
    const { data, error } = await patch(urls.onlineOrders.completeOrder + _id);
    if (error) return toast.warn(error.message);
    await getOrder(id);
    toast.success(data.message);
  };

  const confirmPaymentOrder = async (_id, id) => {
    const { data, error } = await patch(urls.onlineOrders.confirmPayment + _id);
    if (error) return toast.warn(error.message);
    await getOrder(id);
    toast.success(data.message);
  };

  const acceptOrder = async (_id, id, cb) => {
    const { data, error } = await patch(urls.onlineOrders.acceptOrder + _id);
    if (error) return toast.warn(error.message);
    getOrder(id);
    toast.success(data.message);
    if (typeof cb === "function") cb();
  };

  return {
    plans,
    getPlans,
    isLoading,
    subscribe,
    concepts,
    getConcepts,
    subscriptions,
    onlineOrders,
    getInventory,
    inventory,
    addSale,
    acceptOrder,
    completeOrder,
    getSales,
    sales,
    getOrder,
    getActiveKiosks,
    kiosks,
    order,
    getOnlineOrders,
    confirmPaymentOrder,
    getMySubscriptions,
  };
};

export default useBusiness;
