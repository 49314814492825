export default [
  {
    label: "Calories",
    value: "Calories",
  },
  {
    label: "Total Fat",
    value: "Total Fat",
  },
  {
    label: "Saturated Fat",
    value: "Saturated Fat",
  },
  {
    label: "Trans Fat",
    value: "Trans Fat",
  },
  {
    label: "Manounsaturated Fat",
    value: "Manounsaturated Fat",
  },
  {
    label: "Polyunsaturated Fat",
    value: "Polyunsaturated Fat",
  },
  {
    label: "Carbs",
    value: "Carbs",
  },
  {
    label: "Fiber",
    value: "Fiber",
  },
  {
    label: "Sugars",
    value: "Sugars",
  },
  {
    label: "Protein",
    value: "Protein",
  },
  {
    label: "Cholesterol",
    value: "Cholesterol",
  },
  {
    label: "Sodium",
    value: "Sodium",
  },
  {
    label: "Calcium",
    value: "Calcium",
  },
  {
    label: "Magnesum",
    value: "Magnesum",
  },
  {
    label: "Potassium",
    value: "Potassium",
  },
  {
    label: "Iron",
    value: "Iron",
  },
  {
    label: "Zinc",
    value: "Zinc",
  },
  {
    label: "Phosphorus",
    value: "Phosphorus",
  },
  {
    label: "Vitamin A",
    value: "Vitamin A",
  },
  {
    label: "Vitamin C",
    value: "Vitamin C",
  },
  {
    label: "Thiamin B1",
    value: "Thiamin B1",
  },
  {
    label: "Riboflavin B2",
    value: "Riboflavin B2",
  },
  {
    label: "Niacin B3",
    value: "Niacin B3",
  },
  {
    label: "Vitamin B6",
    value: "Vitamin B6",
  },
  {
    label: "Folic Acid B9",
    value: "Folic Acid B9",
  },
  {
    label: "Vitamin B12",
    value: "Vitamin B12",
  },
  {
    label: "Vitamin D",
    value: "Vitamin D",
  },
  {
    label: "Vitamin E",
    value: "Vitamin E",
  },
  {
    label: "Vitamin K",
    value: "Vitamin K",
  },
];
