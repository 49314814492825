import { useEffect, useState } from "react";
import CurrencyInput from "../../components/CurrencyInput";
import Input from "../../components/Input";
import Button from "../../components/Button";
import useBanking from "../../hooks/api/useBanking";
import Loader from "../../components/Loader";
import Select from "../../components/Select";
import useWallet from "../../hooks/api/useWallet";
import { useNavigate } from "react-router-dom";
import routes, { getUrl } from "../../utils/routes";

function Withdraw(props) {
  const [selectedAccount, setSelectedAccount] = useState(null);

  const [pin, setPin] = useState("");
  const [amount, setAmount] = useState(null);

  const navigate = useNavigate();

  const {
    isLoading: banksLoading,
    savedAccounts,
    getSavedAccounts,
  } = useBanking();

  const { withdraw, isLoading } = useWallet();

  useEffect(() => {
    getSavedAccounts();
  }, []);

  return (
    <div>
      <h2 className="t-blue">Withdraw</h2>
      <br />
      {banksLoading && (
        <div>
          <Loader loading={banksLoading} />
          <br />
        </div>
      )}
      <div className="input-with">
        <div className="inputs">
          <CurrencyInput
            placeholder="Amount"
            amount={amount}
            onChange={setAmount}
          />
          <Input
            isPassword
            value={pin}
            onChange={setPin}
            placeholder="Transaction Pin"
            type="number"
          />
        </div>
        <Select
          value={selectedAccount?.label}
          onSelect={setSelectedAccount}
          placeholder="Account"
          options={savedAccounts.map((a) => ({
            label: `${a.accountNumber} (${a.accountName} - ${a.bankName})`,
            value: {
              label: `${a.accountNumber} (${a.accountName} - ${a.bankName})`,
              value: a._id,
            },
            disabled: !a.active,
          }))}
        />
        <div className="btns-links">
          <Button
            onClick={() =>
              navigate(
                getUrl(routes.app.wallet.baseUrl + routes.app.wallet.pin)
              )
            }
            title="Change Pin"
            outline
            className="add-link"
          />
          <Button
            onClick={() =>
              navigate(
                getUrl(routes.app.wallet.baseUrl + routes.app.wallet.accounts)
              )
            }
            title="Add bank account"
            outline
            className="add-link"
          />
        </div>
        <br />
        <Button
          loading={isLoading}
          onClick={() =>
            withdraw({ amount, pin, account: selectedAccount.value })
          }
          disabled={!amount || !selectedAccount || !pin}
          title="Continue"
          className="btn-submit"
        />
      </div>
    </div>
  );
}

export default Withdraw;
