import { useLocation } from "react-router-dom";

function ViewSubmissions(props) {
  const {
    state: { submission },
  } = useLocation();
  return (
    <div>
      <h2>{submission.name}</h2>
      <p>{submission.description}</p>
      <br />
      <h2>Origin</h2>
      <p>{submission.origin}</p>
      <br />
      <h2>Packaging</h2>
      <p>{submission.packaging}</p>
      <br />
      <h2>Best Eating Time</h2>
      <p>{submission.bestEatingTime}</p>
      <br />
      <h2>Best Serve to</h2>
      <p>{submission.bestServeTo}</p>
      <br />
      <h2>Menus</h2>
      <ul>
        {submission.menuItems.map((m, idx) => (
          <li key={idx}>
            {m.name} ({m.description})
          </li>
        ))}
      </ul>
      <br />
      <h2>Ingredients</h2>
      <ul>
        {submission.ingredients.map((m, idx) => (
          <li key={idx}>
            {m.name} ({m.specification})
          </li>
        ))}
      </ul>
      <br />
      <h2>Recipe</h2>
      <ul>
        {submission.recipe.map((m, idx) => (
          <li key={idx}>
            {m.ingredient} ({m.unit})g - {m.instruction}
          </li>
        ))}
      </ul>
      <br />
      <h2>Prep Instructions</h2>
      <ul>
        {submission.prep.map((m, idx) => (
          <li key={idx}>{m}</li>
        ))}
      </ul>
      <br />
      <h2>Prep Machineries</h2>
      <ul>
        {submission.prepMachines.map((m, idx) => (
          <li key={idx}>
            {m.quantity} {m.name} ({m.model})
          </li>
        ))}
      </ul>
      <br />
      <h2>iCart Machineries</h2>
      <ul>
        {submission.icartMachines.map((m, idx) => (
          <li key={idx}>
            {m.quantity} {m.name} ({m.model})
          </li>
        ))}
      </ul>
      <br />
    </div>
  );
}

export default ViewSubmissions;
