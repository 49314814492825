import { useEffect, useState } from "react";
import { getPayUrl } from "../../utils";
import urls from "../../api/urls";
import useAPI from "./useAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useWallet = (init) => {
  const [transactions, setTransactions] = useState([]);
  const { isLoading, get, post, patch } = useAPI();
  const [wallet, setWallet] = useState({ balance: 0 });

  const navigate = useNavigate();

  const getWallet = async () => {
    const { error, data } = await get(urls.wallet.me);
    if (error || !data) return;
    setWallet(data.data.wallet);
  };

  const createPayLink = async (id, gateway) => {
    const { data, error } = await post(
      urls.wallet.link.replace("id", id) + `?gateway=${gateway}`
    );
    if (error) return;
    window.open(getPayUrl(data.data.checkoutUrl), "_blank");
  };

  const withdraw = async (fdata, cb) => {
    const { error, data } = await post(urls.wallet.withdraw, fdata);
    if (error || !data) return;
    toast.success(data.message);
    navigate(-1);
    if (typeof cb === "function") cb();
  };

  const changePin = async (fdata, cb) => {
    const { error, data } = await patch(urls.wallet.changePin, fdata);
    if (error || !data) return;
    toast.success(data.message);
    navigate(-1);
    if (typeof cb === "function") cb();
  };

  const getTransactions = async (pageNumber = 1, limit = 30) => {
    const { error, data } = await get(
      urls.wallet.transactions
        .replace("{{pageNumber}}", pageNumber)
        .replace("{{limit}}", limit)
    );
    if (error || !data) return;
    setTransactions(data.data.transactions);
  };

  const initFund = async (amount, cb) => {
    const { error, data } = await post(urls.wallet.fund, { amount });
    if (error) return;
    if (typeof cb === "function") cb();
    window.open(getPayUrl(data.data.checkoutUrl), "_blank");
  };

  const refresh = async () => {
    await getWallet();
    await getTransactions();
  };

  useEffect(() => {
    if (init) refresh();
    // eslint-disable-next-line
  }, []);

  return {
    isLoading,
    wallet: wallet || {},
    transactions: transactions || [],
    getTransactions,
    getWallet,
    initFund,
    refresh,
    createPayLink,
    withdraw,
    changePin,
  };
};

export default useWallet;
