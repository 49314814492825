import { useContext, useEffect, useState } from "react";
import OnlineOrders from "../../components/table/active/OnlineOrders";
import useBusiness from "../../hooks/api/useBusiness";
import Input from "../../components/Input";
import Select from "../../components/Select";
import store from "../../utils/store";
import AppContext from "../../contexts/AppContext";

const types = {
  pending: "pending",
  completed: "completed",
  delivered: "delivered",
  processing: "processing",
};

function Dashboard(props) {
  const [orderStat, setOrderStat] = useState({
    pending: 0,
    completed: 0,
    delivered: 0,
    processing: 0,
  });
  const [kiosk, setKiosk] = useState(store.getItem("kiosk"));
  const [orderType, setOrderType] = useState(types.pending);
  const { getOnlineOrders, onlineOrders, isLoading, getActiveKiosks, kiosks } =
    useBusiness();
  const { user } = useContext(AppContext);

  useEffect(() => {
    getActiveKiosks();
  }, []);

  return (
    <div>
      <div
        style={{ cursor: "pointer" }}
        className="flex align-center links-create"
      >
        <div
          onClick={() => setOrderType(types.pending)}
          className={`link-create transitioned f400 t-default flex flex-column justify-center align-center opened`}
        >
          <span>Pending Order</span>
          <h3>{orderStat.pending}</h3>
        </div>
        <div
          onClick={() => setOrderType(types.processing)}
          className={`link-create transitioned f400 t-default flex flex-column justify-center align-center opened`}
        >
          <span>Processing Order</span>
          <h3>{orderStat.processing}</h3>
        </div>
        <div
          onClick={() => setOrderType(types.completed)}
          className={`link-create transitioned f400 t-default flex flex-column justify-center align-center opened`}
        >
          <span>Completed Orders</span>
          <h3>{orderStat.completed}</h3>
        </div>
        <div
          onClick={() => setOrderType(types.delivered)}
          className={`link-create transitioned f400 t-default flex flex-column justify-center align-center opened`}
        >
          <span>Delivered Orders</span>
          <h3>{orderStat.delivered}</h3>
        </div>
      </div>
      <br />
      {user.canViewKiosks && (
        <Select
          value={kiosk}
          onSelect={(v) => {
            setKiosk(v);
            store.setItem("kiosk", v);
          }}
          options={kiosks.map((k) => ({
            label: `${k.id} (${k.location.label})`,
            value: k.id,
          }))}
          placeholder="Active Kiosks"
        />
      )}
      <br />
      <OnlineOrders
        kiosk={kiosk}
        updateStat={setOrderStat}
        type={orderType}
        getOnlineOrders={getOnlineOrders}
        isLoading={isLoading}
        onlineOrders={onlineOrders}
        title="Pending Online Orders"
      />
    </div>
  );
}

export default Dashboard;
