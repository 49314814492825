import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useAPI from "./useAPI";
import urls from "../../api/urls";
import { toast } from "react-toastify";

function useMenus(props) {
  const [menus, setMenus] = useState([]);
  const [requests, setRequests] = useState([]);
  const [render, setRender] = useState([]);
  const { get, post, isLoading } = useAPI();

  const getMenus = async () => {
    const { data, error } = await get(urls.businesses.menus.baseUrl);
    if (error) return;
    setMenus(data.data.menus);
    setRender(data.data.menus);
  };

  const search = (q) =>
    setRender(
      menus.filter((m) => m.name.toLowerCase().includes(q.toLowerCase()))
    );

  return {
    requests,
    isLoading,
    menus,
    setRequests,
    getMenus,
    search,
    render,
  };
}

export default useMenus;
