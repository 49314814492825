import useBusiness from "../../../hooks/api/useBusiness";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils";
import svg from "../../../utils/svg";
import { useContext, useEffect } from "react";
import Table from "../Table";
import { capitalizeFirstLetter } from "../../../utils/func";
import DataContext from "../../../contexts/DataContext";

function OnlineOrders({
  title = "iCart Online Orders",
  type,
  updateStat,
  kiosk,
}) {
  const { getOnlineOrders, onlineOrders, isLoading, getOrder } = useBusiness();
  const navigate = useNavigate();
  const { playOrderAlarm } = useContext(DataContext);

  useEffect(() => {
    getOnlineOrders(kiosk);
  }, [kiosk]);

  useEffect(() => {
    if (playOrderAlarm) getOnlineOrders();
  }, [playOrderAlarm]);

  useEffect(() => {
    if (typeof updateStat === "function") {
      updateStat({
        pending: onlineOrders.filter((o) => o.status === "pending").length,
        completed: onlineOrders.filter((o) => o.status === "completed").length,
        delivered: onlineOrders.filter((o) => o.status === "delivered").length,
        processing: onlineOrders.filter((o) => o.status === "processing")
          .length,
      });
    }
  }, [onlineOrders]);

  return (
    <div>
      <Table
        onSearch={(v) => {
          if (v.length === 6) getOrder(v, true);
          if (!v) getOnlineOrders();
        }}
        showSearch={true}
        loading={isLoading}
        title={`${capitalizeFirstLetter(type)} Orders`}
        head={[
          ...head,
          {
            title: "Actions",
            target: "_id",
            render: (id) => (
              <button
                className="action flex align-center"
                onClick={() =>
                  navigate("/online-orders/details", {
                    state: { data: onlineOrders.filter((k) => k._id == id)[0] },
                  })
                }
              >
                {svg.eye()} <span className="f700">VIEW</span>
              </button>
            ),
          },
        ]}
        data={onlineOrders.filter((o) => o.status === type)}
      />
    </div>
  );
}

export default OnlineOrders;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Address",
    target: "deliveryTo.address",
    render: (v) => <p style={{ paddingRight: 10, marginBottom: 10 }}>{v}</p>,
  },
  //   {
  //     title: "Customer Name",
  //     target: "customerInformation.name",
  //   },
  {
    title: "Customer Phone",
    target: "customerInformation.phoneNumber",
    render: (p) => <a href={`tel:${p}`}>{p}</a>,
  },
  {
    title: "Order ID",
    target: "id",
  },
  {
    title: "Status",
    target: "status",
    render: (value) => (
      <span className={`status ${value}`}>
        {value.toString().toUpperCase()}
      </span>
    ),
  },
  {
    title: "Payment",
    target: "paymentMethod",
  },
  {
    title: "Paid",
    target: "paymentCompleted",
    render: (value) => (
      <span className={`status ${value}`}>
        {value.toString().toUpperCase()}
      </span>
    ),
  },

  //   {
  //     title: "Delivery To",
  //     target: "deliveryTo",
  //     render: (v) => (
  //       <a
  //         href={`https://www.google.com/maps/?q=${v.lat},${v.lng}`}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         {v.address}
  //       </a>
  //     ),
  //   },

  //   {
  //     title: "Payment",

  //     // render: (items) =>
  //     //   items
  //     //     .map(
  //     //       (i, idx) =>
  //     //         `${capitalizeFirstLetter(i.item.name)} x${
  //     //           i.quantity
  //     //         } ${currencyFormatter(i.item.price * i.quantity)}, `
  //     //     )
  //     //     .join(", "),
  //   },

  //   {
  //     title: "Selling Price",
  //     target: "priceCapture",
  //     render: currencyFormatter,
  //   },
  {
    title: "Date",
    target: "createdAt",
    render: formatDate,
  },
];
